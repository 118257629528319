import React from "react"
import { graphql } from "gatsby"
import DoctorCard from "@components/common/Doctor/doctor-card";

const DoctorsGrid = (params) => {
    const {employers} = params;

    return (
        <div className={"row"}>
            {employers.map(_ => (
                <DoctorCard employer={_} key={_.name} />
            ))}
        </div>
    )
}

export const query = graphql`
    fragment DoctorCard on STRAPI_EMPLOYER {
        strapi_id
        name
        slug
        title
        specjalizacja
        nr_telefonu
        facebook_url
        instagram_url
        znanylekarz_url
        description {
            data {
                description
            }
        }
        publishedAt
        cover {
            localFile {
                childImageSharp {
                    gatsbyImageData(
                        width: 310
                        height: 310
                        placeholder: DOMINANT_COLOR
                        formats: [AUTO, WEBP]
                    )
                }
            }
        }
    }
`

export default DoctorsGrid;