import * as React from "react"
import type { HeadFC, PageProps } from "gatsby"
import Layout from "@components/layout";
import {graphql} from "gatsby";
import DoctorsGrid from "@components/common/Doctor/doctors-grid";
import CommonPageHeader from "@components/common/CommonPageHeader/CommonPageHeader";

const EmployersPage: React.FC<PageProps> = (params: any) => {

    return (
        <Layout>
            <main>
                <CommonPageHeader title="Nasz Zespół" subtitle="O Nas" />
                <section className={"team-area"}>
                    <div className={"container"}>
                        <DoctorsGrid employers={params.data.allStrapiEmployer.nodes} />
                    </div>
                </section>
            </main>
        </Layout>
    )
}

export default EmployersPage;

export const pageQuery = graphql`
        query {
            allStrapiEmployer(sort: {order: ASC, fields: order}) {
                nodes {
                    ...DoctorCard
                }
            }
        }


`

export const Head: HeadFC = () => <title>O Nas - Fizjoproject Kiekrz</title>
