import React from 'react';
import { Link } from 'gatsby';
import {GatsbyImage, getImage} from "gatsby-plugin-image";

const DoctorCard = ({ employer }) => {

    return (
        <>
            <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="team-wrapper team-box-2 text-center mt-30 mb-30">
                    <Link to={`/about/${employer.strapi_id}/${employer.slug}`}>
                        <div className="team-thumb">
                            <GatsbyImage
                                image={getImage(employer.cover?.localFile)}
                                alt={employer.cover?.alternativeText}
                            />
                        </div>
                    </Link>
                    <div className="team-member-info mt-35 mb-25">
                        <h3><Link to={`/about/${employer.strapi_id}/${employer.slug}`}>{employer.name}</Link></h3>
                        <h6 className="f-500 text-up-case letter-spacing color-employer-text">{employer.title}</h6>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DoctorCard;